import { Link } from 'react-router-dom';

import { DASHBOARD } from 'admin/routes/pathNames';
import { DOCUMENT_AND_POLICIES } from 'user/routes/pathNames';
import { ReactComponent as SmallOfficeSafeLogo } from 'shared/assets/images/bridge_compliance_header.svg';
import { styles } from './styles';

interface IOfficeSafeLogoProps {
	portal: 'adminPortal' | 'userPortal';
	customClass?: string;
}

const OfficeSafeLogo = ({ portal, customClass }: IOfficeSafeLogoProps) => {
	const classes = styles();

	const handleLogoNavigation = () => {
		switch (portal) {
			case 'adminPortal':
				return DASHBOARD;

			case 'userPortal':
				return DOCUMENT_AND_POLICIES;
		}
	};

	return (
		<Link
			className={`${classes.pcihipaaLogo} ${customClass}`}
			to={handleLogoNavigation()}
		>
			<SmallOfficeSafeLogo />
		</Link>
	);
};

export default OfficeSafeLogo;
