import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
	createStyles({
		pcihipaaLogo: {
			display: 'flex',
			alignItems: 'center',
			textDecoration: 'none',
			color: '#8E8C8E',
			backgroundColor: '#fff',
			fontSize: '24px',
			fontWeight: 'bold',
			marginLeft: '-40px',
			'&:hover': {
				backgroundColor: '#fff !important'
			},
			'& > svg': {
				height: '80px',
				width: '240px'
			}
		}
	})
);
